import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import "./pagination.css";

import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import CustomInput from '../CustomInput/CustomInput';

// Example items, to simulate fetching from another resources.
// const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

// function Items({ currentItems }) {
//   return (
//     <>
//       {currentItems &&
//         currentItems.map((item) => (
//           <div>
//             <h3>Item #{item}</h3>
//           </div>
//         ))}
//     </>
//   );
// }

export default function Pagination({ itemsPerPage, items, page, currentPageFunction, handlePageChange, setPageLimitfunction }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState();
  const [pageVal, setPageVal] = useState('');

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;

  // const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items / itemsPerPage);
  
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );

    if (pageVal > 0) {
      let val = Math.ceil(pageVal);
      // let newObj={selected: 10,fromPageVal:true}
      // currentPageFunction(newObj);
      // const newOffset = (val * itemsPerPage) % items.length;
      // setItemOffset(newOffset);
      if (event?.isPrevious) {
        if(val>1){
        handlePageChange(val - 1);
        setPageVal(val - 1);
        }
      }
      if(event?.isNext) {
        if(pageCount>page){
          handlePageChange(val + 1);
          setPageVal(val + 1);
        }
       
      }
    } else {
      if (event?.isPrevious) {
        currentPageFunction({ ...event, fromPageVal: false });
      }
      if (event?.isNext) {
        if(pageCount>page){
        currentPageFunction({ ...event, fromPageVal: false });
        }
      }
      const newOffset = (event.selected * itemsPerPage) % items.length;
      setItemOffset(newOffset);
    }
  };

  const onPageChange = (page) => {

  }

  const searchPage = (page, e) => {
    if (e.keyCode == 13) {
      let val = Math.ceil(page);
      if (pageCount >= val && val > 0) {
        setPageVal(page);
        handlePageChange(val)
      }
      if (pageCount >= val)
        setPageVal(page);
    }
  }

  const searchPageCal = (page, e) => {
    let val = Math.ceil(page);
    if (pageCount >= val && val > 0) {
      setPageVal(page);
    }
    if (pageCount >= val)
      setPageVal(page);
  }


  const setLimitPaginationFunction = (value) => {
    setPageLimitfunction(value);
    setPageVal('');
  }
  return (
    <>
      {/* <Items currentItems={currentItems} /> */}
     
      {items > 0 &&
        <Grid container spacing={2} my={1}>

        
          {/* <Grid item xs={4} textAlign={'right'} className='paginationDropdown'>
            {page} out of {pageCount}
          </Grid> */}
          <Grid item xs={8} textAlign={'right'} className='paginationDiv'>
            <div  style={{display:'flex',flexdirection:'row'}}>
         
            <ReactPaginate
              // breakLabel={` ${page}  out  of ${pageCount}  `}
              breakLabel={''}
              nextLabel="Next"
              // onPageChange={handlePageClick}
              onClick={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className='displayPagination'
              pageClassName='displayLi'
              previousClassName='previousClassName'
              nextClassName='nextClassName'
              activeLinkClassName='activeLinkClassName'
              marginPagesDisplayed={0}
            />
             <span style={{marginLeft:'8px',marginTop:'2px'}}>{page} out of {pageCount}</span>
            </div>
          </Grid>
          <Grid item xs={2} className='paginationSearch'>
            <CustomInput
              variant="outlined"
              fullWidth
              name="search"
              placeholder="Enter Page..."
              // label="Search"
              value={pageVal}
              onKeyDown={(e) => {
                { isNaN(e.target.value) ? '' : searchPage(e.target.value, e) };

              }}
              onChange={(e) => {
                { isNaN(e.target.value) ? '' : searchPageCal(e.target.value, e) };

              }}

              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: 'rounded',
                },
              }}
            // inputProps={{
            //   style: {
            //     padding: "16px 8px",
            //     fontSize: 12
            //   }
            // }}
            />
          </Grid>
          <Grid item xs={2} className='paginationDropdown'>
            <FormControl fullWidth
            // inputProps={{
            //       style: {
            //         padding: "12px 8px",
            //         fontSize: 12
            //       }
            //     }}
            >
              <CustomInput
                labelId="demo-simple-select-label"
                id="page"
                value={itemsPerPage}
                // label="Page"
                onChange={(e) => {
                  setLimitPaginationFunction(e.target.value)

                }}
                className="dense"
                SelectProps={{
                  native: true,
                  classes: {
                    select: "dense"
                  },
                }}
                select
                InputLabelProps={{
                  shrink: true, style: {
                  }
                }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                  style: {
                    height: 33,
                  }
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
              </CustomInput>
            </FormControl>
          </Grid>
        </Grid>
      }
    </>
  );
}

// Add a <div id="container"> to your HTML to see the component rendered.
