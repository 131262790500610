import React, { useState } from 'react';
import MainLayout from '../layouts/MainLayout';
import ContributorQueries from '../ContributorQueries/ContributorQueries';

 
const queriesPages = () => {
    const [navParams, setNavParams] = useState({
        navTitle: "Contributor Queries",
        navSubTitle: "Welcome to ImagesBazaar Contributor Panel"
    });
 
    const handleNavParams = (obj) => {
        setNavParams({
            ...navParams,
            ...obj
        });
    };
 
    return (
        <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
            <ContributorQueries handleNavParams={handleNavParams} />
        </MainLayout>
    );
}
 
export default queriesPages
