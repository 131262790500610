// src/redux/querySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: {
    isError: false,
    message: '',
  },
  queries: [],
  isSuccess: false,
};

export const query = createSlice({
  name: 'queryData',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    addQuery: (state, action) => {
      state.queries.push(action.payload);
      state.isSuccess = true; // Set success to true when a query is added
      state.error.isError = false; // Reset any previous error
      state.error.message = ''; // Clear error message
    },
    setQueryError: (state, action) => {
      state.error = action.payload; // Set the error state
      state.isSuccess = false; // Set success to false on error
    },
    clearQueries: (state) => {
      state.queries = []; // Clear the queries array
      state.isSuccess = false; // Reset success state
      state.error.isError = false; // Reset error state
      state.error.message = ''; // Clear error message
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  addQuery,
  setQueryError,
  clearQueries,
} = query.actions;

export default query.reducer;

// Selector to get query state
export const querySelector = (state) => state.queryData;