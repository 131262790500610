import React from "react";
import FooterLogo from "../Assets/images/footer-logo.png";
import { ReactComponent as FaebookIcon } from "../Assets/svgs/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "../Assets/svgs/linkedinicon.svg";
import { ReactComponent as TwitterIcon } from "../Assets/svgs/twitter-icon.svg";
import { ReactComponent as WhatsappIcon } from "../Assets/svgs/whatsapp-icon.svg";
import { ReactComponent as PhoneIcon } from "../Assets/svgs/phone-icon-footer.svg";
import { ReactComponent as MailIcon } from "../Assets/svgs/mail-icon-login.svg";
import { ReactComponent as PinterestIcon } from "../Assets/svgs/pinterest-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import PaymentFooter from "../Assets/images/payment-footer.png";
import { Typography } from "@mui/material";
import ApiConfig from "../../config/ApiConfig";
import { staticDataBase } from "../../data/staticData";
import { useEffect } from "react";
import { useState } from "react";
import { CMSDataSelector } from "../../redux/cmsData";
import { useSelector } from "react-redux";

const FooterContributor = ({ cmsFooterPage }) => {
  const navigate = useNavigate();
  const [footerData, setFooterData] = useState(null);
  const { FooterPage } = useSelector(CMSDataSelector);

  useEffect(() => {
    let cmsFooter = FooterPage
      ? FooterPage?.items?.length > 0 &&
        JSON?.parse(FooterPage?.items[0]?.key_value)
      : {};
    setFooterData(cmsFooter);
  }, [FooterPage]);

  return (
    <>
      <div className="mainFooterContributor" style={{ marginTop: "200px" }}>
        <div className="footer-section" style={{ borderRadius: "25px" }}>
          <div className="footerMainContent">
            <img
              onClick={() => {
                navigate("/");
              }}
              className="main-footer-logo"
              src={FooterLogo}
              alt="FooterLogo"
            />
            <div className="footer-categories">
              <div className="info-section-footer">
                <div className="info-name">
                  <a
                    className="mail-link-footer"
                    href={`mailto:contributors@imagesbazaar.com`}
                  >
                    <MailIcon className="footer-contact-icons" />
                  </a>
                  <div className="left-margin-footer">
                    <Typography
                      component="a"
                      variant="mainsectiontitle"
                      className="footer-links"
                      href={`mailto:contributors@imagesbazaar.com`}
                    >
                      contributors@imagesbazaar.com
                    </Typography>
                  </div>
                </div>
              </div>
              {/*              
                    <div
                      className="info-social-icons alignContentRIght"
                      
                    >
                      <Typography
                        component="div"
                        variant="mainsectiontitle"
                        className="footer-titles styleRight"
                        style={{marginRight:'-70px',marginTop:'-12px'}}
                      >
                        Follow Us
                      </Typography>
                      <div className="footer-icon-group">
                        <a
                          href="https://www.facebook.com/ImagesBazaarIndia/"
                          target="_blank"
                        >
                          <div className="squareIcon">
                            <FaebookIcon className="footer-social-page" />
                          </div>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/imagesbazaar/"
                          target="_blank"
                        >
                          <div className="squareIcon">
                            <LinkedInIcon className="footer-social-page" />
                          </div>
                        </a>
                        <a
                          href="https://twitter.com/imagesbazaarcom"
                          target="_blank"
                        >
                          <div className="squareIcon">
                            <TwitterIcon className="footer-social-page" />
                          </div>
                        </a>
                     
                      </div>
                    </div> */}
              <div className="footer-rights">
                <Typography
                  component="div"
                  variant="mainsectiontitle"
                  className="font-white footer-rights-title alignText-center"
                >
                  © {`${ApiConfig.LIVE_WEBSITE_URL}`}. A division of Mash Audio
                  Visuals Pvt. Ltd. All rights reserved.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterContributor;
