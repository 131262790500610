import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  appSettingData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
  sideMenuOpen:{
    isopen:true,
  }
};

export const appSettingSlice = createSlice({
  name: "appSettingData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.appSettingData.loading = action.payload;
    },
    setAppSettingData: (state, action) => {
      state.appSettingData.items = action.payload;
    },
    setAppSettingDataError: (state, action) => {
      state.appSettingData.error = action.payload;
      state.appSettingData.items = [];
    },
    setSideMenuOpen:(state,action)=>{
      state.sideMenuOpen.isopen=action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setAppSettingData, setAppSettingDataError,  setSideMenuOpen, } =
  appSettingSlice.actions;

export default appSettingSlice.reducer;

export const appSettingDataSelector = (state) => state.appSettingData;
