import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Box } from '@mui/material';
import { ContributorAddQuery } from '../../../services/contributorServices';
// import { addQuery, setQueryError, querySelector } from '../../../redux/query';
 
const ContributorQueries = () => {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false);
//   const { loading, error } = useSelector(querySelector);
const [error,setError]=useState(null);

 
  const handleSubmit = async(e) => {
    e.preventDefault();
    if (query.trim()) {
      let resp=await ContributorAddQuery({query:query});
      setQuery('');
    } else {
      //dispatch(setQueryError({ isError: true, message: 'Query cannot be empty' }));
    }
  };
 
  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <TextField
        label="Enter your query"
        variant="outlined"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        sx={{ mb: 2, width: '100%', maxWidth: '1200px' }}
        error={error}
        // helperText={error.isError ? error.message : ''}
        multiline
        minRows={5}
        maxRows={10}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
        sx={{ color: 'white' }}
      >
        Submit
      </Button>
    </Box>
  );
};
 
export default ContributorQueries;
