
import { ReactComponent as DashboardIcon } from "../Components/Assets/svgs/dashboardIcon.svg";
import {ReactComponent as DownloadIcon} from "../Components/Assets/svgs/downloadicon.svg";
import {ReactComponent as ClockIcon} from "../Components/Assets/svgs/clockIcon.svg";
import {ReactComponent as ImageIcon} from "../Components//Assets/svgs/image-icon.svg";
import {ReactComponent as MoneyInHand} from "../Components//Assets/svgs/moneyInHandIcon.svg";
import {ReactComponent as UploadIcon} from "../Components//Assets/svgs/uploadIcon.svg";
import {ReactComponent as BankIcon} from "../Components//Assets/svgs/bankIcon.svg";
import {ReactComponent as QueryIcon} from "../Components//Assets/svgs/queryIcon.svg";
import {ReactComponent as NocsDocIcon} from "../Components//Assets/svgs/nocsDocIcon.svg";
import {ReactComponent as UpdateArrowIcon} from "../Components//Assets/svgs/updateArrowIcon.svg";
//updateArrowIcon
export const  IconArr=[DownloadIcon,DashboardIcon,ClockIcon,ImageIcon,MoneyInHand,UploadIcon,BankIcon,QueryIcon,NocsDocIcon,UpdateArrowIcon];

export const sideMenu = [
  {
    pathName: '/contributor/dashboard',
    label: 'Dashboard',
    icon: 1,
  },
  {
    pathName: '/contributor/payments',
    label: 'Payment History',
    icon: 2,
  },
  {
    pathName: '/contributor/images',
    label: 'View Images',
    icon: 3,
  },

  {
    pathName: '/contributor/soldImages',
    label: 'Sold Images',
    icon: 4,
  },
  {
    pathName: '/contributor/folderList',
    label: 'Upload',
    icon: 5,
  },
  {
    pathName: '/contributor/bankDetail',
    label: 'Bank Detail',
    icon: 6,
  },
  {
    pathName: '/contributor/queries',
    label: 'Queries',
    icon: 7,
  },
  {
    pathName: '/contributor/noc',
    label: 'NOC',
    icon: 8,
  },
  {
    pathName: '/contributor/changePassword',
    label: 'Change Password',
    icon: 9,
  },
  // {
  //   pathName: '/contributor/queries',
  //   label: 'Queries',
  //   icon: '',
  // },

];
