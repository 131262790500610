import { Box } from "@mui/system";
import React, { useState } from "react";
import Sidebar from "../Header/Sidebar";
import Navbar from "../Header/Navbar";
import Divder from "../commonContributer/Divder";
import Footer from "../../Common/Footer";
import FooterContributor from "../../Common/FooterContributor";
import { appSettingDataSelector } from "../../../redux/appSetting";
import { useSelector } from "react-redux";

function MainLayout(props) {
  const [showSmallNav, setShowSmallNav] = useState(true);
  const { sideMenuOpen } = useSelector(appSettingDataSelector);
  let drawerWidth = sideMenuOpen?.isopen == true ? 260 : 112;
  console.log(sideMenuOpen, "sideMenuOpen");
  return (
    <div style={{ backgroundColor: "#b8dcfa26" }}>
      <Sidebar showSmallNav={showSmallNav} setShowSmallNav={setShowSmallNav} />
      <Box
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          paddingTop: 2,
          paddingRight: 2,
        }}
      >
        <Navbar
          headingTitle={props.navTitle}
          headingSubtitle={props.navSubTitle}
          rightComp={props.navBarRightComp || null}
        />
        <Divder />
        {props.children}
        {/* <FooterContributor /> */}
      </Box>
      <FooterContributor />
  
    </div>
  );
}

export default MainLayout;
