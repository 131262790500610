export const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;
export const ZOOM_IMAGE_URL = process.env.REACT_APP_1200_1800_IMAGE_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_MAIN = process.env.REACT_APP_API_URL;
export const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;
export const BASE_THUMBNAIL_IMAGE_URL =
  process.env.REACT_APP_THUMBNAIL_IMAGE_URL;
export const LIVE_WEBSITE_URL = process.env.REACT_APP_LIVE_WEBSITE_URL;
export const VIDEO_BASE_URL=process.env.REACT_APP_VIDEO_BASE_URL;
//REACT_APP_VIDEO_BASE_URL

// export const BASE_RAZORPAY_URL = "https://api.razorpay.com/";
const REACT_APP_SALT = process.env.REACT_APP_SALT;
const GOOGLE_TAG_MANAGER_KEY = process.env.REACT_APP_GTAG_KEY;

let ApiConfig = {
  token: null,
  contibuter_token: null,
  BASE_URL,
  BASE_IMAGE_URL,
  LIVE_WEBSITE_URL,
  REACT_APP_SALT,
  GOOGLE_TAG_MANAGER_KEY,
  S3_BASE_URL,
  VIDEO_BASE_URL,
  getImagesSearchList: `${BASE_URL}imagesearch`,
  imagesearchByName: `${BASE_URL}imagesearchByName`,
  ExclusiveimagesearchByName: `${BASE_URL}ExclusiveimagesearchByName`,
  getStarsCollectionSearchList: `${BASE_URL}starimagesearch`,
  getStarCollectionSearchByName: `${BASE_URL}starimagesearchByName`,
  searchLogs: `${BASE_URL_MAIN}common/searchLog`,
  getVideoSearchResults: `${BASE_URL_MAIN}videos/videoSearch`,
  getVideoSearchByName: `${BASE_URL}videosearchByName`,
  autosuggest: `${BASE_URL}autosuggest`,
  signUpUser: `${BASE_URL_MAIN}user/register`,
  otpVerify: `${BASE_URL_MAIN}user/otp_verify`,
  resendOtp: `${BASE_URL_MAIN}user/resend_otp`,
  //Common apis
  getJobDescription: `${BASE_URL_MAIN}common/listAllJobs`,
  getCountry: `${BASE_URL_MAIN}common/listAllCountry`,
  getStarIndustry: `${BASE_URL_MAIN}common/listOfstarIndustries`,
  getState: `${BASE_URL_MAIN}common/listAllState`,
  loginUser: `${BASE_URL_MAIN}user/login`,
  socialLoginUser: `${BASE_URL_MAIN}user/socialLogin`,
  updateUser: `${BASE_URL_MAIN}user/updateProfile`,
  getImageKeywords: `${BASE_URL_MAIN}common/imageKeywords`,
  appSetting: `${BASE_URL_MAIN}common/appSetting`,
  getUserDetails: `${BASE_URL_MAIN}user/accountDetail`,
  contactUs: `${BASE_URL_MAIN}common/contactUs`,
  singleImageData: `${BASE_URL_MAIN}common/imageInfo`,
  orderPlanData: `${BASE_URL_MAIN}user/upgradeImagePrice`,
  proposalDetails: `${BASE_URL_MAIN}common/ProposalDetails`,
  backgroundImage:`${BASE_URL_MAIN}admin/BackgroundImage`,
  bannersList:`${BASE_URL_MAIN}common/homepagebannerimagesList`,
  videoDownLoad:`${BASE_URL_MAIN}common/downloadvideo`,

  // Video APIs
  getVideoKeywords: `${BASE_URL_MAIN}common/videoKeywords`,
  singleVideoData: `${BASE_URL_MAIN}videos/videoInfo`,
  getentireShootVideo: `${BASE_URL_MAIN}videos/videoEntireShoot`,
  //Forgot Password
  forgotPassword: `${BASE_URL_MAIN}user/forgetPassword`,

  //Reset Password
  resetPassword: `${BASE_URL_MAIN}user/resetPassword`,

  //Resize Image
  resizeImage: `${BASE_URL_MAIN}common/imageResize`,

  //Recover Password
  recoverPassword: `${BASE_URL_MAIN}user/recoverPassword`,

  getentireShoot: `${BASE_URL_MAIN}shoot/entireShoot`,
  getSameModelImages: `${BASE_URL_MAIN}common/samemodelimages`,
  getModelReleaseImages: `${BASE_URL_MAIN}shoot/entireReleaseimages`,
  getSimilarImages: `${BASE_URL_MAIN}common/similarimages`,
  getImageInformation: `${BASE_URL_MAIN}shoot/imageDetail`,
  getallwishlist: `${BASE_URL_MAIN}user/wishList`,
  getAllProposals: `${BASE_URL_MAIN}user/listOfProposals`,
  addtowishlist: `${BASE_URL_MAIN}user/addToWishList`,
  removewishlist: `${BASE_URL_MAIN}user/removeFromWishList`,
  getAllCartData: `${BASE_URL_MAIN}user/cartList`,
  addImageToCart: `${BASE_URL_MAIN}user/addToCart`,
  upgradeImageVideoToCart: `${BASE_URL_MAIN}user/upgradeToCart`,
  removeImageFromCart: `${BASE_URL_MAIN}user/removeFromCart`,
  browseMainCategory: `${BASE_URL_MAIN}common/allcategory`,
  videosBrowseCategory: `${BASE_URL_MAIN}common/allvideocategory`,
  starsCollectionBrowseCategory: `${BASE_URL_MAIN}common/allStarCategory`,
  browseSubCategory: `${BASE_URL_MAIN}common/subcategory`,
  getSubscribedPlanData: `${BASE_URL_MAIN}user/subscribedOrders`,
  getImageDownloadData: `${BASE_URL_MAIN}user/listOfivsDownloads`,
  getNplanImageDownloadData: `${BASE_URL_MAIN}user/orderDownloads`,
  getSubsciptionPlanData: `${BASE_URL_MAIN}user/subscribedImages`,
  getPrivilegeData: `${BASE_URL_MAIN}user/privilageClientlistforUser`,

  //Payment Integration
  createOrderData: `${BASE_URL_MAIN}order/create`,
  getAmexOrderData: `${BASE_URL_MAIN}common/amexRetrieveOrder`,
  captureAmexOrderData: `${BASE_URL_MAIN}common/amexCapture`,
  updateOrderData: `${BASE_URL_MAIN}order/datatransact`,
  paymentUnsuccessFull:`${BASE_URL_MAIN}order/transactionUnsuccess`,
  paymentOnlineNotification:`${BASE_URL_MAIN}order/payonline`,
  // Order Data
  getAllOrders: `${BASE_URL_MAIN}order/list`,
  getOrderData: `${BASE_URL_MAIN}order`,
  downloadImage: `${BASE_URL_MAIN}order/downloadImage`,
  makeImageDownload: `${BASE_URL_MAIN}admin/downloadimage`,
  sampleImageDownload: `${BASE_URL_MAIN}common/downloadzip`,
  sampleImageDownloadNew: `${BASE_URL_MAIN}common/downloadSampleimageZip`,
  mailDownloadLink: `${BASE_URL_MAIN}shoot/shareLink`,
  mailDownloadLinkWithoutToken: `${BASE_URL_MAIN}admin/shareLink`,
  orderImageslist: `${BASE_URL_MAIN}common/orderImageslist`,
  privilegeDownloadImage: `${BASE_URL_MAIN}user/addPrivlageClient`,

  //RAZORPAY Integration
  createRazorPayOrder: `${BASE_URL_MAIN}common/razorpay`,
  // AMEX Integration
  createAmexSession: `${BASE_URL_MAIN}common/createAmexSession`,
  // PAY10 Integration
  createPay10Screen: `${BASE_URL_MAIN}order/pay10`,
  // ZaakPay Integration
  createZaakPayScreen: `${BASE_URL_MAIN}order/zaakpay`,
  // CCAvenue Integration
  createCCAvenueScreen: `${BASE_URL_MAIN}order/ccAvenue`,
  //COMMON ADMIN API FOR USER
  ibdownloadimage: `${BASE_URL_MAIN}admin/ibdownloadimage`,
  // Exchange Currency Api
  exchangeCurrency: `https://v6.exchangerate-api.com/v6/318b4068c88dbb7591fec73e/pair/`,

  // Contributer
  loginContributer: `${BASE_URL_MAIN}contributor/contributorLogin`,
  contributorBankDetail: `${BASE_URL_MAIN}contributor/contributorBankDetails`,
  uploadImage: `${BASE_URL_MAIN}contributor/UploadContributorImages`,
  getAllConributorImageData: `${BASE_URL_MAIN}contributor/listOfContributorimage`,
  contributorLogout: `${BASE_URL_MAIN}contributor/contributorLogout`,
  deleteUploadedImage: `${BASE_URL_MAIN}contributor/deleteUploadedFile`,
  contributorSoldImages: `${BASE_URL_MAIN}contributor/listOfContributorSoldimage`,
  getContributorUploadImages: `${BASE_URL_MAIN}contributor/getcontributorUploadImageList`,
  getFolderNames: `${BASE_URL_MAIN}contributor/fileFolderList`,
  contributorResetPWD: `${BASE_URL_MAIN}contributor/resetContributorPassword`,
  contributorInfoUpdate:`${BASE_URL_MAIN}contributor/contributorupdate`,
  contributorDashboard: `${BASE_URL_MAIN}contributor/contributorDashboard`,
  contributorPaymentHistory: `${BASE_URL_MAIN}contributor/contributorPaymentDetails`,
  constributorSoldImagesHistory: `${BASE_URL_MAIN}contributor/getcontributorOrderDetails`,
  contributorAddqueries:`${BASE_URL_MAIN}contributor/addQueries`,
  contributorQueries:`${BASE_URL_MAIN}contributor/contributorquerylist`,
  contributorQueryHistory:`${BASE_URL_MAIN}contributor/listofQueryMessages`,
  contributorSendMessage:`${BASE_URL_MAIN}contributor/sendMessageBycontributor`,
  contributorFolderChecking:`${BASE_URL_MAIN}contributor/ImagefolderExists`,
  constributorNocListing:`${BASE_URL_MAIN}contributor/listOfNocs`,
  contributorUploadNoc:`${BASE_URL_MAIN}contributor/uploadNoc`,
  assignNocsToImage:`${BASE_URL_MAIN}contributor/linkNocwithImage`,
  deleteNoc:`${BASE_URL_MAIN}contributor/deleteNocById`,

  // Exclusive image List

  getExclusiveImagesSearchList: `${BASE_URL}Exclusiveimagesearch`,

  //CMS
  getCMSdata: `${BASE_URL_MAIN}common/cmsContent`,
  getFAQData: `${BASE_URL_MAIN}common/listofFaq`,
  listOfTestimonial: `${BASE_URL_MAIN}common/listofTestimonial`,
  listOfHorizontalAndVerticalImage:`${BASE_URL_MAIN}common/sampleimagelist`,

  //models
  createModelUserStep1:`${BASE_URL_MAIN}model/createModelUser`,
  UploadModelImages:`${BASE_URL_MAIN}model/UploadModelImages`,
  modelRazorPayment:`${BASE_URL_MAIN}model/modelRazorPay`,
  successPayment:`${BASE_URL_MAIN}model/successPayment`,

  //ImagesUploadSearch
  uploadSearchImage:`${BASE_URL_MAIN}common/searchByImage`,
};
///api/model/createModelUser
export default ApiConfig;
