import { createTheme } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

export const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#464255",
    },
    secondaryText: {
      main: "#fff0",
      contrastText: "#A3A3A3",
    },
    buttonNormal: {
      main: "#F3F2F7",
      contrastText: "#464255",
      ":disabled": {
        backgroundColor: "#ff0",
      },
    },
    buttonPrimary: {
      main: "#58CDFF",
      contrastText: "#FFF",
      ":disabled": {
        backgroundColor: "#ff0",
      },
    },
    buttonInfo: {
      main: "#E5F5FF",
      contrastText: "#FFF",
      ":disabled": {
        backgroundColor: "#ff0",
      },
    },
    buttonSecondary: {
      main: "#464255",
      contrastText: "#FFF",
      ":disabled": {
        backgroundColor: "#ff0",
      },
    },
    helpTopicsColors: {
      backgroundColor: "white",
      contrastText: "#FFF",
      ":disabled": {
        backgroundColor: "#ff0",
      },
    },
    pricingBoxColors: {
      largePackBackgroundColor: "#f0ffea",
      smallPackBackgroundColor: "#eaf0ff",
      webPackBackgroundColor: "#fdeafe",
      singleImageBackgroundColor: "#ececec",
      largePackSelectedBackgroundColor: "#e5f2de",
      webPackSelectedBackgroundColor: "#f4e1f5",
      smallPackSelectedBackgroundColor: "#c8d3f3",
      singleImageSelectedBackgroundColor: "#e0dddd",
      trendingBackgroundColor:"#F3CFC6"
    },
    badgePrimary: {
      main: "#58CDFF",
      contrastText: "#FFF",
    },
    iconButtonPrimary: {
      main: "#464255",
      light: "#FBFBFB",
      contrastText: "#fff",
    },
    iconButtonNavbar: {
      main: "#464255",
      // light: "#FBFBFB",
    },
    appColor: {
      main: "#fff0",
      contrastText: "#464255",
    },
    info: {
      main: "#58CDFF",
    },
    success: {
      main: "#12B347",
    },
    danger: {
      main: "#c80000",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow: "none",
          textTransform: "none",
          "&.commonButtonStyles": {
            fontWeight: 600,
            color: "#FFFFFF",
            backgroundColor: "#272727",
            "&:hover": {
              backgroundColor: "#474747",
            },
            "&:disabled": {
              backgroundColor: "#747474",
            },
          },
          "&.commonButtonStylesDelete": {
            fontWeight: 600,
            color: "#FFFFFF",
            backgroundColor: "rgb(153, 51, 0)",
            "&:hover": {
              backgroundColor: "#474747",
            },
            "&:disabled": {
              backgroundColor: "#747474",
            },
          },
          "&.browseCategoryButtonStyle": {
            background: "transparent",
            justifyContent: "start",
            alignItems: "center",
            padding: "8px 15px",
            fontWeight: "400",
            color: "white",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "18px",
            "@media only screen and (min-width: 280px) and (max-width: 749px)":
            {
              fontSize: "12px",
              lineHeight: "16px",
            },
            "&:hover": {
              background: "#f6faff",
              color: "black",
            },
            "&.selectedButton": {
              background: "#f6faff",
              color: "black",
            },
          },
          "&.freeDownloadButtonStyle": {
            backgroundColor: "#A291F0",
            padding: "20px 30px",
            fontWeight: "600",
            color: "black",
          },
          "&.largeCallToActionButtonStyle": {
            backgroundColor: "black",
            padding: "20px 40px",
            fontWeight: "600",
            color: "white",
          },
          "&.largeButtonForSubscribe": {
            backgroundColor: "black",
            padding: "20px 100px",
            fontWeight: "600",
            color: "white",
          },
          "&.titleButtonStyle": {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "transparent",
            padding: "10px 20px",
            border: "2px solid black",
            textTransform: "uppercase",
            fontWeight: "600",
            color: "black",
          },
          "&.popularSearchButton": {
            color: "#212121",
            backgroundColor: "rgb(249,249,249)",
            padding: "10px 15px",
            fontWeight: "600",
            border: "3px solid #8B8B8B",
          },
          "&.closeCircleButton": {
            color: "white",
            padding: "10px",
            minWidth: "auto",
            backgroundColor: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "&.callToActionButtonStyle": {
            color: "white",
            backgroundColor: "black",
            padding: "15px 20px",
            fontWeight: "600",
            "&:disabled": {
              backgroundColor: "#5b5b5b",
            },
          },
          "&.downloadButtonStyle": {
            color: "white",
            backgroundColor: "black",
            padding: "20px 40px",
            fontWeight: "600",
          },
          "&.confirmationButtonStyle": {
            color: "#fff",
            backgroundColor: "black",
            padding: "15px 20px",
            fontWeight: "600",
            "&.cancelButton": {
              color: "black",
              backgroundColor: "transparent",
              border: "2px solid black",
            },
          },
          "&.printButtonStyle": {
            color: "white",
            backgroundColor: "#58CDFF",
            padding: "10px 10px",
            fontWeight: "600",
          },
          "&.filterButtonStyle": {
            color: "black",
            backgroundColor: "#E9E9E9",
            padding: "15px 20px",
            fontWeight: "600",
            "&.selected": {
              color: "white",
              backgroundColor: "#000",
            },
            "@media only screen and (min-width: 280px) and (max-width: 749px)":
            {
              padding: "12px 10px",
            },
          },
          "&.sidebarLayoutButtonStyle": {
            color: "white",
            backgroundColor: "black",
            padding: "15px 20px",
            fontWeight: "600",
            minWidth: "auto",
            "@media only screen and (min-width: 280px) and (max-width: 1024px)":
            {
              padding: "8px 15px",
            },
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            "&.notSelected": {
              backgroundColor: "transparent",
              color: "black",
              fill: "black",
            },
            "&:hover": {
              backgroundColor: "black",
              color: "white",
              fill: "white",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingRight: "32px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "transparent-dialog": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "input:disabled": {
            cursor: "not-allowed",
          },
          ".Mui-disabled": {
            cursor: "not-allowed",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.BaseIconButton": {
            fontSize: "22px",
            borderRadius: "10px",
            padding: "10px 10px",
            margin: "0 0 0 20px",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "black",
              opacity: 1,
            },
          },
          "&.ArrowIconButton": {
            fontSize: "22px",
            borderRadius: "10px",
            padding: "10px 10px",
            margin: "0",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "black",
              opacity: 1,
            },
          },
          "&.ImageMenuButtonVertical": {
            borderRadius: "6px",
            padding: "8px",
            "&:hover": {
              backgroundColor: "black",
              opacity: 1,
            },
          },
          "&.zoomImageButton": {
            borderRadius: "50%",
            backgroundColor: "rgb(0,0,0,0.5)",
            padding: "10px",
            "&:hover": {
              backgroundColor: "black",
              opacity: 1,
            },
          },
          "&.ImageMenuButtonHorizontal": {
            borderRadius: "10px",
            padding: "8px",
            "&:hover": {
              backgroundColor: "black",
              opacity: 1,
            },
          },
        },
        // Name of the slot
      },
    },
    MuiBadge: {
      styleOverrides: {
        // Name of the slot
        badge: {
          borderRadius: "50%",
          backgroundColor: "#5d5d5d",
          color: "white",
          right: "-10%",
          top: "-14%",
          fontSize: "13px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        // Name of the slot
        primary: {
          fontSize: '14px',
        },
       
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#000",
          fontSize: "14px",
          "&.Mui-selected": {
            color: "#fff",
            backgroundColor: "#000",
          },
          "&.Add-border": {
            borderRight: "1px solid rgba(0, 0, 0, 0.1)",
            paddingRight: "34px",
            // marginRight: '16px',
            paddingLeft: "30px",
          },
        },
      },
    },
    
    MuiTypography: {
      styleOverrides: {
        // Name of the slot
        paragraph: {
          marginBottom: "3px",
        },
      },
    },
    // MuiListItemButton: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-selected": {
    //         backgroundColor: "#000",
    //         color: "#fff",
    //         "&:hover": {
    //           background: "#C9C7B7",
    //           color: "#000"
    //         },
    //       },
    //       "&:hover": {
    //         background: "#C9C7B7",
    //         color: "#000"
    //       },
    //     },
    //   },
    // },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#000 !important',
          },
          '&.Mui-selected.sidebar-item:hover': {
            backgroundColor: '#000 !important',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 7,
          paddingRight: 7,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          background: "#fff",
          "&:hover": {
            background: "#fff",
          },
        },
      },
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          padding: "0",
          "&::before": {
            display: "none",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ".py0": {
          paddingBottom: "0px !important",
          paddingTop: "0px !important",
        },
        ".p0": {
          padding: "0px !important",
        },
        ".fontWeight500": {
          fontWeight: "500 !important",
        },
        ".fontWeight600": {
          fontWeight: "600 !important",
        },
        ".colorPrimary": {
          color: "#464255 !important",
        },
        '.colorWhite': {
          color: '#fff !important',
        },
        ".colorGray": {
          color: "#BCBEBE !important",
        },
        ".sectionLeftMargin": {
          marginLeft: "30px",
        },
        ".my5px": {
          marginTop: 5,
          marginBottom: 5,
        },
        ".my3": {
          marginTop: 16,
          marginBottom: 16,
        },
        ".mx3": {
          marginLeft: 16,
          marginRight: 16,
        },
        ".img-fluid": {
          width: "100%",
          height: "auto",
        },
        ".rounded-2": {
          borderRadius: 8,
        },
        ".rounded-3": {
          borderRadius: 16,
        },
        ".borderBottom-1": {
          borderBottom: "1px solid #d7d7d7",
          marginTop: 5,
        },
        ".border-1": {
          border: "1px solid #d7d7d7",
          marginTop: 5,
        },
        ".maxWidth-25": {
          maxWidth: "25%",
          minWidth: "200px",
        },
        ".textDecoration-none": {
          textDecoration: "none !important",
        },
        ".print:last-child": {
          pageBreakAfter: "auto !important",
        },
        ".background-light-gray": {
          background: "#d2d2d294",
        },
        ".alignText-center": {
          textAlign: "center",
        },
      },
    },
    
  },

  typography: {
    root: {
      marginBottom: 5,
    },
    h1: {
      fontSize: "29px",
      fontWeight: 500,
    },
    h2: {
      fontSize: "25px",
      fontWeight: 500,
    },
    h3: {
      fontSize: "20px",
      fontWeight: 500,
    },
    mainpagetitle: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "22px",
      "@media only screen and (min-width: 280px) and (max-width: 749px)": {
        fontSize: "15px",
        lineHeight: "20px",
      },
      "&.primaryColor": {
        color: "black",
      },
      "&.mainpagetitle-bolder": {
        fontWeight: "700",
        textTransform: "capitalize",
      },
      "&.transformUppercase": {
        textTransform: "capitalize",
      },
      "&.secondaryColor": {
        color: "#212121",
      },
      "&.cartTotalColor": {
        color: "#8F8989",
      },
      "&.alignText-center": {
        textAlign: "center",
      },
      "&.boldFont": {
        fontWeight: "600",
      },
      "&.header-menus": {
        "@media only screen and (min-width: 280px) and (max-width: 1024px)": {
          display: "none",
        },
      },
      "&.font-white": {
        color: "white",
      },
      "&.margin-r-10": {
        marginRight: "10px",
      },
      "&.whitespacewrap": {
        whiteSpace: "nowrap",
      },
    },
    mainsectiontitle: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "15px",
      lineHeight: "20px",
      color: "#000000",
      "@media only screen and (min-width: 280px) and (max-width: 749px)": {
        fontSize: "13px",
        lineHeight: "18px",
      },
      "&.font-white": {
        color: "white",
      },
      "&.whitespacewrap": {
        whiteSpace: "nowrap",
      },
      "&.mainsectiontitle-bolder": {
        fontWeight: "700",
        cursor: "pointer",
        textTransform: "uppercase",
      },
      "&.alignText-center": {
        textAlign: "center",
      },
      "&.font-red": {
        color: "#ff7171",
      },
      "&.underLineText": {
        textDecoration: "underline",
      },
      "&.secondaryColor": {
        color: "#212121",
      },
      "&.boldFont": {
        fontWeight: "600",
      },
      "&.topSpaceDivider10": {
        marginTop: "10px",
      },
      "&.animationLink": {
        cursor: "pointer",
        "&:hover": {
          animation: "0.5s fade-in",
          color: "red",
        },
      },
      "&.pie-menu-title": {
        display: "flex",
        marginTop: "-30px",
        justifyContent: "center",
        textTransform: "uppercase",
        color: "#ffffff",
        "@media only screen and (min-width: 280px) and (max-width: 500px)": {
          marginTop: "-20px",
        },
        "@media only screen and (min-width: 501px) and (max-width: 749px)": {
          marginTop: "-25px",
        },
        "@media only screen and (min-width: 1025px) and (max-width: 1450px)": {
          marginTop: "-25px",
        },
      },
      "&.footer-links": {
        color: "#cacaca",
        textDecoration: "none",
        "&:hover": {
          color: "white",
          fontWeight: "600",
        },
      },
      "&.blueLinks": {
        color: "#005bfc",
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: "500",
      },
      "&.footer-titles": {
        color: "white",
        fontWeight: "600",
      },
      "&.alignText-left": {
        textAlign: "left",
      },
      "&.leftSpace20": {
        paddingLeft: "20px",
      },
    },
    sectiondata: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      color: "#000000",
      "@media only screen and (min-width: 280px) and (max-width: 749px)": {
        fontSize: "12px",
        lineHeight: "16px",
      },
      "&.font-white": {
        color: "white",
      },
      "&.topSpaceDivider10": {
        marginTop: "10px",
      },
      "&.leftSpace20": {
        paddingLeft: "20px",
      },
      "&.boldFont": {
        fontWeight: "600",
      },
      "&.browseCategoryButton": {
        color: "white",
        cursor: "pointer",
        "&:hover": {
          color: "black",
        },
      },
      "&.line-height": {
        lineHeight: "20px",
      },
      "&.alignText-center": {
        textAlign: "center",
      },
      "&.alignText-Left": {
        textAlign: "left",
      },
      "&.mailTelLinks": {
        color: "#005bfc",
        textDecoration: "underline",
        cursor: "pointer",
        "&:hover": {
          color: "white",
        },
      },
      "&.blueLinks a": {
        color: "#005bfc",
        textDecoration: "underline",
        cursor: "pointer",
      },
      "&.3E3EColor": {
        color: "#3e3e3e",
      },
      "&.dullBlackColor": {
        color: "#8d8d8d",
      },
      "&.topSpaceDivider15": {
        marginTop: "15px",
        "@media only screen and (min-width: 280px) and (max-width: 749px)": {
          marginTop: "10px",
        },
      },
      "&.footer-links": {
        color: "#cacaca",
        textDecoration: "none",
        cursor: "pointer",
        marginBottom: "5px",
        "&:hover": {
          color: "white",
          fontWeight: "600",
        },
      },
      "&.topSpaceDivider5": {
        marginTop: "5px",
      },
      "&.alignText-left": {
        textAlign: "left",
      },
    },
    sectionsubdata: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#000000",
      "@media only screen and (min-width: 280px) and (max-width: 749px)": {
        fontSize: "10px",
        lineHeight: "12px",
      },
      "&.font-white": {
        color: "white",
      },
      "&.boldFont": {
        fontWeight: "600",
      },
      "&.line-height": {
        lineHeight: "20px",
      },
      "&.alignText-center": {
        textAlign: "center",
      },
      "&.sample-link": {
        color: "#005bfc",
        cursot: "pointer",
      },
      "&.topSpaceDivider15": {
        marginTop: "15px",
        "@media only screen and (min-width: 280px) and (max-width: 749px)": {
          marginTop: "10px",
        },
      },
    },
    contentData: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#000000",
      "@media only screen and (min-width: 280px) and (max-width: 749px)": {
        fontSize: "12px",
        lineHeight: "16px",
      },
      "&.font-white": {
        color: "white",
      },
      "&.topSpaceDivider10": {
        marginTop: "10px",
      },
      "&.leftSpace20": {
        paddingLeft: "20px",
      },
      "&.boldFont": {
        fontWeight: "600",
      },
      "&.sample-link": {
        color: "#005bfc",
        cursot: "pointer",
      },
      "&.browseCategoryButton": {
        color: "white",
        cursor: "pointer",
        "&:hover": {
          color: "black",
        },
      },
      "&.line-height": {
        lineHeight: "20px",
      },
      "&.alignText-center": {
        textAlign: "center",
      },
      "&.alignText-Left": {
        textAlign: "left",
      },
      "&.mailTelLinks": {
        color: "#005bfc",
        textDecoration: "underline",
        cursor: "pointer",
        "&:hover": {
          color: "white",
        },
      },
      "&.blueLinks a": {
        color: "#005bfc",
        textDecoration: "underline",
        cursor: "pointer",
      },
      "&.3E3EColor": {
        color: "#3e3e3e",
      },
      "&.dullBlackColor": {
        color: "#8d8d8d",
      },
      "&.topSpaceDivider15": {
        marginTop: "15px",
        "@media only screen and (min-width: 280px) and (max-width: 749px)": {
          marginTop: "10px",
        },
      },
      "&.footer-links": {
        color: "#cacaca",
        textDecoration: "none",
        cursor: "pointer",
        marginBottom: "5px",
        "&:hover": {
          color: "white",
          fontWeight: "600",
        },
      },
      "&.topSpaceDivider5": {
        marginTop: "5px",
      },
      "&.alignText-left": {
        textAlign: "left",
      },
    },
    h6: {
      fontSize: "16px",
    },
    p: {
      fontSize: "14px",
    },
    "14A3": {
      fontSize: "14px",
      color: "#A3A3A3",
    },
    "12A3": {
      fontSize: "12px",
      color: "#A3A3A3",
    },
    "16A3": {
      fontSize: "12px",
      color: "#838383",
    },
    "15px4625": {
      fontSize: "15px",
      color: "#464255",
      fontWeight: "500",
    },
    "25pxBold4625": {
      fontSize: "25px",
      color: "#464255",
      fontWeight: "600",
    },
  },
  backgroundColor: {
    box: {
      main: "#fff",
      primary: "#b8dcfa26",
    },
  },
  shape: {
    borderRadiusPrimary: (val) => val,
    borderRadiusSecondary: (val) => 2 * val,
  },
});
