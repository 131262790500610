import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "./login";
import homepageReducer from "./homepage";
import passwordReducer from "./password";
import jobCountryStateReducer from "./jobCountryState";
import wishlistDataReducer from "./wishlist";
import cartDataReducer from "./cart";
import imageInformationReducer from "./imageInformation";
import appSettingReducer from "./appSetting";
import paymentHistoryReducer from "./paymentHistory";
import ordersReducer from "./orders";
import proposalsReducer from "./proposals";
import imageSubscriptionReducer from "./imageDownload";
import checkoutDataReducer from "./checkout";
import accountImageListReducer from "./accountImageList";
import videoInformationReducer from "./videoInformation";
import loginContributerReducer from "./contributerLogin";
import bankDataReducer from "./bank";
import imageDataReducer from "./contributorImages";
import soldImageDataReducer from "./contributorSoldImages";
import uploadImageDataReducer from "./contributorUploadImages";
import folderDataReducer from "./contributorFolderList";
import soldImageHistoryDataReducer from "./contributorSoldImagesHistory";
import queriesDataReducer from "./contributorQueriesList";
import imagesNocReducer from "./contributorNocImages";
import paymentDataReducer from './payments';
import cmsDataReducer from './cmsData';
import queryReducer from  './query';
export const store = configureStore({
  reducer: {
    appSettingData: appSettingReducer,
    loginUserData: loginReducer,
    homePageData: homepageReducer,
    passwordData: passwordReducer,
    jobCountryStateData: jobCountryStateReducer,
    wishlistData: wishlistDataReducer,
    cartData: cartDataReducer,
    imageInformationData: imageInformationReducer,
    ordersData: ordersReducer,
    proposalData: proposalsReducer,
    imageSubscribedData: imageSubscriptionReducer,
    checkoutData: checkoutDataReducer,
    accountImageListData: accountImageListReducer,
    videoInformationData: videoInformationReducer,
    loginContributerData: loginContributerReducer,
    bankData: bankDataReducer,
    ImagesData: imageDataReducer,
    SoldImagesData: soldImageDataReducer,
    NocData: imagesNocReducer,
    QueriesLists:queriesDataReducer,
    UploadImagesData: uploadImageDataReducer,
    SoldImagesHistory: soldImageHistoryDataReducer,
    paymentsAllData: paymentDataReducer,
    paymentHistoryData: paymentHistoryReducer,
    CMSData: cmsDataReducer,
    FolderData: folderDataReducer,
    queryData: queryReducer,

  },
});
