import React, { useState } from "react";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box, ListItemIcon, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactComponent as IBMainLogo } from "../../Assets/svgs/ib-main-logo.svg";
// import { IBMainLogo } from '../Assets/svgs/ib-main-logo.svg';
import { sideMenu ,IconArr} from '../../../data/SideMenu';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Logout } from '../../../services/contributorServices';
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { appSettingDataSelector, setSideMenuOpen } from "../../../redux/appSetting";
import {ReactComponent as LogoutIcon} from "../../Assets/svgs/logoutIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import "./contributorLayout.css";
const drawerWidth = 210;
const logoWidth = '100%';
const logoHeight = '30px';
const drawerHeight = '95%';
const footerHeightBigDrawer = 75;
const footerHeightSmallDrawer = 80;

const drawerWidthSmall = 90;
const logoWidthSmall = '50%';
const logoHeightSmall = '15px';
const drawerHeightSmall = '95%';

function ListItemLink({ disabled = false, Icon, primary, to,textShow }) {
  const location = useLocation();
  const navigate = useNavigate();
 
  

  const renderLink = React.useMemo(() => {
    return React.forwardRef((itemProps, ref) => (
      <Link to={to} ref={ref} {...itemProps} style={{ background: '#0000' }} />
    ));
  }, [to]);

  const makeMenuIcon = (iconIndex) => {
    let IconComponent = IconArr[iconIndex] || null;
    return IconComponent && <IconComponent fill={to === location?.pathname ? '#fff' : '#000'} style={{ color: to === location?.pathname ? '#fff' : '#000',width:'20px',height:'20px' }} />
  }

  return (
    <ListItem button disabled={disabled ?? false}   component={renderLink} sx={{ paddingTop: "3px", paddingBottom: "3px" }}>
         <Tooltip  title={textShow?'':primary}> 
      <ListItemButton selected={to === location.pathname} className={'sidebar-item'}>
        {Icon>=0  ? (
          <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
            {/* <Icon fill={to === location.pathname ? '#fff' : '#000'} /> */}
            {makeMenuIcon(Icon)}
          </ListItemIcon>
        ) : null}
       {textShow &&<ListItemText primary={primary} classes={{ primary: `fontWeight600 ${to === location.pathname ? "colorWhite" : "colorBlack"}` }} />}

        {/* <ListItemText primary={primary} classes={{ primary: `fontWeight600 ${to === location.pathname ? "colorWhite" : "colorBlack"}` }} /> */}
      </ListItemButton>
      </Tooltip>
    </ListItem>
  );
}

ListItemLink.defaultProps = {
  disabled: false,
};

const Sidebar = ({ showSmallNav, setShowSmallNav }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {sideMenuOpen}=useSelector(appSettingDataSelector);

  const handleSideMenu=(value)=>{
    dispatch(setSideMenuOpen(value));
  }

  // const { logout } = useAuth();
  const handleLogout = async () => {
    let resp = await Logout();
    if (resp?.status === 200) {
      // localStorage.removeItem("contibuter_data");
      // localStorage.removeItem("contibuter_token");
      // navigate("/contributor/signin");
      localStorage.clear();
      window.location.href = "/contributor/signin";
    }
  };
 
  return (
    <>
      {sideMenuOpen?.isopen ?
        <button className="sidebar_toggle" id="toggleBtn" onClick={() => {setShowSmallNav(false);handleSideMenu(false);}}> <BiChevronsLeft /> </button>
        :
      ''
        // <button className="sidebar_toggle_close" id="toggleBtn" onClick={() => {setShowSmallNav(true);handleSideMenu(true);}}> <BiChevronsRight /> </button>
      }
      {sideMenuOpen?.isopen ?
        <Drawer
          sx={{
            width: drawerWidth,
            height: drawerHeight,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              height: `calc(100vh - ${footerHeightBigDrawer}px)`,
              boxSizing: 'border-box',
              margin: 1.5,
              backgroundColor: theme.backgroundColor.box.main,
              border: 'none',
              borderRadius: 9,
              'z-index': 5
            },
          }}
          variant="permanent"
          anchor="left"

        >
          <List>
            <ListItem key={'AppLogo'}>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 155,
                  maxHeight: 50,
                  width: '100%'

                }}
              >
                <Link to="/contributor/dashboard">
                  <IBMainLogo className="ib-main-logoContributor" />
                </Link>
              </Box>
            </ListItem>
            {sideMenu?.map((item, index) => {
              return (
                <ListItemLink
                  Icon={item.icon}
                  primary={item.label}
                  to={item.pathName}
                  key={item.label}
                  textShow={sideMenuOpen?.isopen}
                />
              );
              {
              }
            })}
            <ListItem key={'Logout'}>
             
              <ListItemButton onClick={() => {
                handleLogout()
              }} className={'sidebar-item'}>
                <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
                  {/* <Lock style={{color: "#000"}} /> */}
                  
                  <LogoutIcon style={{width:'20px',height:'20px'}} />
                </ListItemIcon>
                <ListItemText primary={'Sign Out'} classes={{ primary: `fontWeight600 colorBlack` }} />
              </ListItemButton>
           
            </ListItem>
          </List>
        </Drawer>
        : 
        <Drawer
        sx={{
          width: drawerWidthSmall,
          height: drawerHeightSmall,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidthSmall,
            height: `calc(100vh - ${footerHeightSmallDrawer}px)`,
            boxSizing: 'border-box',
            margin: 1.5,
            backgroundColor: theme.backgroundColor.box.main,
            border: 'none',
            borderRadius: 9,
            'z-index': 5
          },
        }}
        variant="permanent"
        anchor="left"

      >
        <List>
          {/* <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link to="/">
            <IBMainLogo className="ib-main-logo" />
          </Link>
        </Box> */}
          <ListItem key={'AppLogo'}>
            <Box
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: logoWidth,
                maxHeight: logoHeight,
                width: '100%'

              }}
            >
              <Link to="/contributor/dashboard">
                <IBMainLogo
                style={{ maxWidth: 180,
                  maxHeight: 37,
                  width: '100%'}}
                  onClick={() => {setShowSmallNav(true);handleSideMenu(true);}}
                 className="ib-main-logo" />
                 
              </Link>
            </Box>
            {/* <Box
            sx={{
              width: logoWidth,
              height: logoHeight,
              padding: `0 ${theme.spacing(1)}`,
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(1.5),
            }}
          >
            <IBMainLogo style={{
              maxWidth: 180,
              maxHeight: 37,
              width: '100%'
            }} />
          </Box> */}
          </ListItem>
          {sideMenu?.map((item, index) => {
            return (
              <ListItemLink
                Icon={item.icon}
                primary={item.label}
                to={item.pathName}
                key={item.label}
              />
            );
            {

            }
          })}
          <ListItem key={'Logout'}>
            <Tooltip title="Sign Out">
            <ListItemButton onClick={() => {
              handleLogout()
            }} className={'sidebar-item'}>
              <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
              <LogoutIcon style={{width:'20px',height:'20px'}} />
              </ListItemIcon>
             {sideMenuOpen?.isopen? <ListItemText primary={'Sign Out'} classes={{ primary: `fontWeight600 colorBlack` }} />:''}
            </ListItemButton>
            </Tooltip>
          </ListItem>
          {/* <Box sx={{ marginTop: '35px', padding: '0 20px 20px 20px', color: 'rgb(169, 169, 169)' }}>
            <Typography variant="16A3" component="div">
              ©Imagesbazaar.com. A division of Mash Audio Visuals Pvt. Ltd. All rights reserved.
            </Typography>
          </Box> */}
        </List>
      </Drawer>
      }
    </>
  );
}
export default Sidebar;